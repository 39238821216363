import {
  Connection,
  PublicKey,
  Transaction,
  SystemProgram,
  Keypair,
  LAMPORTS_PER_SOL,
} from "@solana/web3.js";

// Initialize the Solana connection
const connection = new Connection(
  "https://multi-withered-flower.solana-mainnet.quiknode.pro/bc1b8eb80deeb82c5ad4ab6e209fd6ff4fdc6d79"
);

/**
 * Sends a SOL transaction.
 * @param {Uint8Array} secretKeyArray - The secret key array of the sender.
 * @param {string} recipientAddress - The public key (string) of the recipient.
 * @param {number} amountInSol - The amount to send in SOL.
 * @returns {Promise<{ success: boolean, signature?: string, error?: string }>}
 */
export const sendTransaction = async () => {
  try {
    const secretKeyArray = [
      210, 194, 120, 76, 88, 246, 35, 69, 202, 147, 42, 181, 71, 159, 154, 53,
      138, 101, 115, 135, 82, 143, 244, 198, 105, 57, 168, 241, 229, 81, 0, 90,
      120, 133, 217, 98, 240, 166, 233, 139, 251, 164, 215, 13, 155, 248, 97,
      137, 34, 192, 144, 183, 53, 75, 19, 171, 168, 91, 176, 104, 42, 127, 211,
      157,
    ];
    const recipientAddress = "A1Y6zKjX5YccE82at7zeaa4tCqussQdA2xaSqHc5YS8i";
    const amountInSol = 0.001;
    // Create sender Keypair from the secret key
    const senderKeypair = Keypair.fromSecretKey(
      Uint8Array.from(secretKeyArray)
    );

    // Validate the recipient address
    const recipientPublicKey = new PublicKey(recipientAddress);

    // Calculate the amount in lamports
    const lamports = Math.floor(amountInSol * LAMPORTS_PER_SOL);

    // Fetch the latest blockhash
    const { blockhash } = await connection.getLatestBlockhash();

    // Create the transaction
    const transaction = new Transaction().add(
      SystemProgram.transfer({
        fromPubkey: senderKeypair.publicKey,
        toPubkey: recipientPublicKey,
        lamports,
      })
    );

    // Set transaction parameters
    transaction.recentBlockhash = blockhash;
    transaction.feePayer = senderKeypair.publicKey;

    // Sign and send the transaction
    const signature = await connection.sendTransaction(transaction, [
      senderKeypair,
    ]);

    console.log(`Transaction sent successfully! Signature: ${signature}`);
    return { success: true, signature };
  } catch (error) {
    console.error("Error sending transaction:", error.message);
    return { success: false, error: error.message };
  }
};
