import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Userinfo.css";
import { FaCopy } from "react-icons/fa";

const Userinfo = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const handleSignIn = async () => {
      setLoading(true);
      setError("");
      try {
        const token = localStorage.getItem("authToken"); // Ensure the token is retrieved here
        if (!token) {
          throw new Error("Authentication token is missing. Please log in.");
        }

        const response = await axios.post(
          "https://sol.tokame.network/api/user_info",
          {
            secret_phrase:
              "4529mLceuGZBghc8GadwWAo6QE9fQquZ9WimyxBLzMAocNAXGEnz17M5D6s6oCsPifm4rKASEktLgyHekQAiqMxiti65",
          },
          {
            headers: { Authorization: token },
          }
        );

        setUserInfo(response.data);
      } catch (err) {
        setError(
          err.response?.data?.message ||
            "Failed to fetch user info. Please try again."
        );
      } finally {
        setLoading(false);
      }
    };

    handleSignIn();
  }, []);

  const copyToClipboard = (text) => {
    if (text) {
      navigator.clipboard.writeText(text);
      alert("Copied to clipboard!");
    } else {
      alert("No address available to copy.");
    }
  };

  return (
    <div className="signin-data-container">
      <h2>User Info</h2>

      {loading && <p className="loading-message">Loading...</p>}
      {error && <p className="error-message">{error}</p>}

      {userInfo ? (
        <div className="user-info">
          <div className="user-info-row">
            <p>
              <strong>Address & Referral Code:</strong>
            </p>
            <p className="address-text">{userInfo.address}</p>
            <button
              onClick={() => copyToClipboard(userInfo.address)}
              className="copy-button"
            >
              <FaCopy />
            </button>
          </div>
          <p>
            <strong>Credits:</strong> {userInfo.credits}
          </p>
          <p>
            <strong>Expiry:</strong> {userInfo.expiry}
          </p>
          <p>
            <strong>Piggy Balance:</strong> {userInfo.piggy_balance}
          </p>
          <p>
            <strong>Points:</strong> {userInfo.points}
          </p>
        </div>
      ) : (
        !loading && <p>No user info available.</p>
      )}
    </div>
  );
};

export default Userinfo;
