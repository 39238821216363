import React, { useState, useRef } from "react";
import "./Dashboard.css";
import SignInData from "./SignInData";
import DepositHistory from "./DepositHistory";
import Userinfo from "./Userinfo";
import Deposit from "./Deposit"; // Import Deposit
import UpdateCredits from "./UpdateCredits";
import Adddeposit from "./Add_deposit";
import Withdraw from "./Withdraw";
import Withdrawalhistory from "./Withdrawalhistory";
import Referrals from "./Referrals";
import Referralcredit from "./Referral_credits";

const Dashboard = () => {
  const [content, setContent] = useState(""); // State to store the content to display
  const depositRef = useRef(); // Ref for the Deposit component

  const handleButtonClick = (buttonName) => {
    let newContent;

    switch (buttonName) {
      case "Sign In":
        newContent = <SignInData />;
        break;
      case "User Info":
        newContent = <Userinfo />;
        break;
      case "Add Deposit":
        newContent = <Adddeposit />;
        break;
      case "Deposit":
        newContent = <Deposit ref={depositRef} />; // Add the Deposit component with a ref
        break;
      case "Update Credits":
        newContent = <UpdateCredits />;
        break;
      case "Update Piggy":
        newContent = "Update Piggy content here";
        break;
      case "Withdrawal":
        newContent = <Withdraw />;
        break;
      case "Referral":
        newContent = <Referrals />;
        break;
        case "ReferralCredits":
          newContent = <Referralcredit />;
          break;
      case "Withdrawal History":
        newContent = <Withdrawalhistory />;
        break;
      case "Deposit History":
        newContent = <DepositHistory />;
        break;
      case "Total Team Business":
        newContent = "Team Business statistics or data here";
        break;
      default:
        newContent = "Click a button to display data here!";
    }
    setContent(newContent);
  };



  return (
    <div className="dashboard-container">
      {/* Header */}
      <h1 className="dashboard-header">Dashboard</h1>

      {/* Display content */}
      <div className="content-display">
        {content || "Click a button to display data here!"}
      </div>

      {/* Buttons Grid */}
      <div className="buttons-grid">
        <button
          className="dashboard-button"
          onClick={() => handleButtonClick("Sign In")}
        >
          Sign In
        </button>
        <button
          className="dashboard-button"
          onClick={() => handleButtonClick("User Info")}
        >
          User Info
        </button>
        <button
          className="dashboard-button"
          onClick={() => {
            handleButtonClick("Deposit");
          }}
        >
          Deposit
        </button>
        <button
          className="dashboard-button"
          onClick={() => handleButtonClick("Add Deposit")}
        >
          Add Deposit
        </button>
        <button
          className="dashboard-button"
          onClick={() => handleButtonClick("Update Credits")}
        >
          Update Credits
        </button>
        <button
          className="dashboard-button"
          onClick={() => handleButtonClick("Update Piggy")}
        >
          Update Piggy
        </button>
        <button
          className="dashboard-button"
          onClick={() => handleButtonClick("Withdrawal")}
        >
          Withdrawal
        </button>
        <button
          className="dashboard-button"
          onClick={() => handleButtonClick("Withdrawal History")}
        >
          Withdrawal History
        </button>
        <button
          className="dashboard-button"
          onClick={() => handleButtonClick("Deposit History")}
        >
          Deposit History
        </button>
        <button
          className="dashboard-button"
          onClick={() => handleButtonClick("Referral")}
        >
          Referral
        </button>
        <button
          className="dashboard-button"
          onClick={() => handleButtonClick("ReferralCredits")}
        >
          Referral Credits
        </button>
        <button
          className="dashboard-button"
          onClick={() => handleButtonClick("Total Team Business")}
        >
          Total Team Business
        </button>
      </div>
    </div>
  );
};

export default Dashboard;
