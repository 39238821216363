import React, { useState, useEffect } from "react";
import "./DepositHistory.css";
import { getreferrals } from "./apiService"; // Adjust the path as necessary

const Referralshistory = () => {
  const [referralsData, setReferralsData] = useState({ address: "", referrals: [] }); // State for referrals data
  const [loading, setLoading] = useState(true); // State for loading
  const [error, setError] = useState(""); // State for errors

  // Fetch referrals data
  const handleFetchReferrals = async () => {
    setLoading(true);
    setError("");
    try {
      const data = await getreferrals(); // Fetch referrals data
      setReferralsData(data);
    } catch (err) {
      setError("Failed to fetch referrals history. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Fetch data on component mount
  useEffect(() => {
    handleFetchReferrals();
  }, []);

  // Copy to clipboard
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    alert("Copied to clipboard!");
  };

  return (
    <div className="deposit-history-container">
      <h2>Referrals History</h2>

      {/* Show loading indicator */}
      {loading && <p>Loading...</p>}

      {/* Show error message */}
      {error && <p className="error-message">{error}</p>}

      {/* Render referrals data */}
      {!loading && referralsData.referrals.length > 0 ? (
        <div className="referrals-history-list">
          <div className="referrals-address">
            <p>
              <strong>Address:</strong>{referralsData.address.substring(0, 10)}
            </p>
          </div>
          {referralsData.referrals.map((referral, index) => (
            <div key={index} className="referral-item">
                              <strong>Referral Address:</strong>{" "}

              <p>
                {referral.length > 20 ? `${referral.substring(0, 10)}...` : referral}
                <button className="copy-button" onClick={() => handleCopy(referral)}>
                  Copy
                </button>
              </p>
            </div>
          ))}
        </div>
      ) : (
        !loading && <p>No referrals found.</p>
      )}
    </div>
  );
};

export default Referralshistory;
