import React, { useState, useEffect } from "react";
import { getreferralcredits } from "./apiService"; // Adjust the path as necessary

const ReferralCredits = () => {
  const [referralCredits, setReferralCredits] = useState([]); // State for referral credits data
  const [loading, setLoading] = useState(true); // State for loading
  const [error, setError] = useState(""); // State for errors

  // Fetch referral credits
  const fetchReferralCredits = async () => {
    setLoading(true);
    setError("");
    try {
      const data = await getreferralcredits(); // Fetch referral credits
      setReferralCredits(data);
    } catch (err) {
      setError("Failed to fetch referral credits. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Call fetchReferralCredits on component mount
  useEffect(() => {
    fetchReferralCredits();
  }, []);

  return (
    <div className="referral-credits-container">
      <h2>Referral Credits</h2>

      {/* Show loading indicator while fetching data */}
      {loading && <p>Loading...</p>}

      {/* Show error message */}
      {error && <p className="error-message">{error}</p>}

      {/* Render referral credits */}
      {!loading && referralCredits.length > 0 ? (
        <div className="referral-credits-list">
          {referralCredits.map((credit, index) => {
            const level = credit.level || "N/A";
            const amount = credit.amount || "0";
            const creditHistory = credit.credit_history || "No details available";
            const createdAt = credit.created_at
              ? new Date(credit.created_at).toLocaleString()
              : "Unknown Date";

            return (
              <div key={index} className="credit-item">
                <p>
                  <strong>Level:</strong> {level}
                </p>
                <p>
                  <strong>Amount:</strong> {amount}
                </p>
                <p>
                  <strong>Details:</strong> {creditHistory}
                </p>
                <p>
                  <strong>Date:</strong> {createdAt}
                </p>
              </div>
            );
          })}
        </div>
      ) : (
        !loading && <p>No referral credits found.</p>
      )}
    </div>
  );
};

export default ReferralCredits;
