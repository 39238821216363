import React, { useState, useEffect } from "react";
import { signIn } from "./apiService"; // Adjust the path as necessary
import "./Userinfo.css";

const SignInData = () => {
  const [loading, setLoading] = useState(false); // State for loading
  const [error, setError] = useState(""); // State for errors
  const [response, setResponse] = useState(""); // State for the sign-in response

  useEffect(() => {
    const handleSignIn = async () => {
      setLoading(true);
      setError("");
      try {
        const signInData = await signIn(); // Call the sign-in API
        setResponse(signInData.message || "Sign-in successful!"); // Update response with a success message
      } catch (err) {
        setError("Failed to sign in or fetch user info. Please try again.");
      } finally {
        setLoading(false);
      }
    };
    handleSignIn();
  }, []);

  return (
    <div className="signin-data-container">
      <h2>Sign In</h2>

      {/* Show loading indicator */}
      {loading && <p>Loading...</p>}

      {/* Show error message */}
      {error && <p className="error-message">{error}</p>}

      {/* Show success response */}
      {!loading && response && <p className="success-message">{response}</p>}
    </div>
  );
};

export default SignInData;
