import React, { useState, useEffect } from "react";
import { getadd_deposit } from "./apiService"; // Adjust the path as necessary

const Add_deposit = () => {
  const [add_deposit, setAdd_deposit] = useState(""); // State for credit response message
  const [loading, setLoading] = useState(true); // State for loading
  const [error, setError] = useState(""); // State for errors

  // Fetch credits
  const handlegetadd_deposit = async () => {
    setLoading(true);
    setError("");
    try {
      const response = await getadd_deposit(); // Fetch credits
      setAdd_deposit(response.message); // Update state with the message
    } catch (err) {
      setError("Failed to fetch credits. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Call handleUpdateCredits on component mount
  useEffect(() => {
    handlegetadd_deposit();
  }, []);

  return (
    <div className="credits-container">
      <h2>Credits Update</h2>

      {/* Show loading indicator while fetching data */}
      {loading && <p>Loading...</p>}

      {/* Show error message */}
      {error && <p className="error-message">{error}</p>}

      {/* Show credit message */}
      {!loading && add_deposit && (
        <p className="deposit-message">
          <strong>{add_deposit}</strong>
        </p>
      )}
    </div>
  );
};

export default Add_deposit;
