import React, { useState, useEffect } from "react";
import { sendTransaction } from "./sendTransaction"; // Adjust the path as necessary

const Deposit = () => {
  const [signature, setSignature] = useState(""); // State for credit response message
  const [loading, setLoading] = useState(true); // State for loading
  const [error, setError] = useState(""); // State for errors

  // Fetch credits
  const handleDeposit = async () => {
    setLoading(true);
    setError("");
    try {
      const response = await sendTransaction(); // Fetch credits
      setSignature(response.signature); // Update state with the message
    } catch (err) {
      setError("Failed to fetch credits. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Call handleUpdateCredits on component mount
  useEffect(() => {
    handleDeposit();
  }, []);
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    alert("Signature copied to clipboard!");
  };
  return (
    <div className="credits-container">
      <h2>Deposit</h2>
      {/* Show loading indicator while fetching data */}
      {loading && <p>Loading...</p>}

      {/* Show error message */}
      {error && <p className="error-message">{error}</p>}

      {/* Show credit message */}
      {!loading && signature && (
        <p>
        <strong>Deposit Signature:</strong>{" "}
        {signature.length > 20
          ? `${signature.substring(0, 10)}...`
          : signature}
        <button
          className="copy-button"
          onClick={() => handleCopy(signature)}
        >
          Copy
        </button>
      </p>
      )}
    </div>
  );
};

export default Deposit;
