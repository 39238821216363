import React, { useState, useEffect } from "react";
import { withdraw } from "./apiService"; // Adjust the path as necessary

const Withdraw = () => {
  const [creditMessage, setCreditMessage] = useState(""); // State for credit response message
  const [loading, setLoading] = useState(true); // State for loading
  const [error, setError] = useState(""); // State for errors

  // Fetch credits
  const handleUpdateCredits = async () => {
    setLoading(true);
    setError("");
    try {
      const response = await withdraw(); // Fetch credits
      setCreditMessage(response.message); // Update state with the message
    } catch (err) {
      setError("Failed to fetch credits. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Call handleUpdateCredits on component mount
  useEffect(() => {
    handleUpdateCredits();
  }, []);

  return (
    <div className="credits-container">
      <h2>Credits Update</h2>

      {/* Show loading indicator while fetching data */}
      {loading && <p>Loading...</p>}

      {/* Show error message */}
      {error && <p className="error-message">{error}</p>}

      {/* Show credit message */}
      {!loading && creditMessage && (
        <p className="credit-message">
          <strong>{creditMessage}</strong>
        </p>
      )}
    </div>
  );
};

export default Withdraw;
