import React from "react";
import Dashboard from "./component/Dashboard"; // Import the Dashboard component
import "./App.css"; // For global styles (optional)
import SignInData from "./component/SignInData"; // Import the Dashboard component


const App = () => {
  return (
    <div className="App">
      <Dashboard />
      {/* <SignInData /> */}

    </div>
  );
};

export default App;
