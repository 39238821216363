import axios from "axios";

const API_BASE_URL = "https://sol.tokame.network/api";
const authToken = localStorage.getItem("authToken"); // Retrieves "your-auth-token"
const secretPhrase = '4529mLceuGZBghc8GadwWAo6QE9fQquZ9WimyxBLzMAocNAXGEnz17M5D6s6oCsPifm4rKASEktLgyHekQAiqMxiti65'

// Function to handle sign-in
export const signIn = async () => {
  const response = await axios.post(`${API_BASE_URL}/signin`, {
    secret_phrase: secretPhrase,
  });
  localStorage.setItem("authToken", response.data.token);
  localStorage.setItem("public_key", response.data.public_key);
  return response.data;
};

// Function to fetch user information
export const getUserInfo = async () => {
  const response = await axios.post(
    `${API_BASE_URL}/user_info`,
    { secret_phrase: secretPhrase },
    {
      headers: { Authorization: authToken },
    }
  );
  return response.data;
};


export const getdeposit_history = async () => {
  const response = await axios.post(
    `${API_BASE_URL}/deposit_history`,
    { secret_phrase: secretPhrase },
    {
      headers: { Authorization: authToken },
    }
  );
  return response.data;
};
export const getWithdrawalhistory = async () => {
  const response = await axios.post(
    `${API_BASE_URL}/withdrawal_history`,
    { secret_phrase: secretPhrase },
    {
      headers: { Authorization: authToken },
    }
  );
  console.log(response.data)
  return response.data;
};

export const getupdate_credits = async () => {
  const response = await axios.post(
    `${API_BASE_URL}/update_credits`,
    { secret_phrase: secretPhrase },
    {
      headers: { Authorization: authToken },
    }
  );
  return response.data;
};

export const getadd_deposit = async () => {
  const response = await axios.post(
    `${API_BASE_URL}/add_deposit`,
    { secret_phrase: secretPhrase },
    {
      headers: { Authorization: authToken },
    }
  );
  return response.data;
};

export const withdraw = async () => {
  const response = await axios.post(
    `${API_BASE_URL}/withdraw`,
    {
      secret_phrase: secretPhrase, token_address: "EnuQpa6w2jPUAxUGYqXedfMjtojL67aUtBfwJB72xUnW"
    },
    {
      headers: { Authorization: authToken },
    }
  );
  return response.data;
};

export const getreferrals = async () => {
  const response = await axios.post(
    `${API_BASE_URL}/referrals`,
    { secret_phrase: secretPhrase },
    {
      headers: { Authorization: authToken },
    }
  );
  return response.data;
};
export const getreferralcredits = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/referral_credits`, {
      headers: { Authorization: authToken },
      data: { secret_phrase: secretPhrase }, // Pass `secret_phrase` as a query parameter
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching referral credits:", error);
    throw new Error("Failed to fetch referral credits.");
  }
};