import React, { useState, useEffect } from "react";
import "./DepositHistory.css";
import { getWithdrawalhistory } from "./apiService"; // Adjust the path as necessary

const Withdrawalhistory = () => {
  const [withdrawalhistorys, setWithdrawalhistory] = useState([]); // State for deposit history
  const [loading, setLoading] = useState(true); // State for loading
  const [error, setError] = useState(""); // State for errors

  // Fetch deposit history
  const handleDepositHi = async () => {
    setLoading(true);
    setError("");
    try {
      const data = await getWithdrawalhistory(); // Fetch deposit history
      setWithdrawalhistory(data);
    } catch (err) {
      setError("Failed to fetch Withdrawal history. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Call handleSignIn on component mount
  useEffect(() => {
    handleDepositHi();
  }, []);

  // Copy to clipboard
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    alert("Signature copied to clipboard!");
  };

  return (
    <div className="deposit-history-container">
      <h2>Withdrawal History</h2>

      {/* Show loading indicator while fetching data */}
      {loading && <p>Loading...</p>}

      {/* Show error message */}
      {error && <p className="error-message">{error}</p>}

      {/* Render deposit history */}
      {!loading && withdrawalhistorys.length > 0 ? (
        <div className="deposit-history-list">
          {withdrawalhistorys.map((withdrawal, index) => (
            <div key={index} className="deposit-item">
              <p>
                <strong>withdrawal Amount (TOKA):</strong> {withdrawal.withdrawal_amt_toka}
              </p>
              <p>
                <strong>withdrawal Amount (USD):</strong> {withdrawal.withdrawal_amt_usd}
              </p>
              <p>
                <strong>withdrawal Signature:</strong>{" "}
                {withdrawal.withdrawal_hash.length > 20
                  ? `${withdrawal.withdrawal_hash.substring(0, 10)}...`
                  : withdrawal.withdrawal_hash}
                <button
                  className="copy-button"
                  onClick={() => handleCopy(withdrawal.withdrawal_hash)}
                >
                  Copy
                </button>
              </p>
            </div>
          ))}
        </div>
      ) : (
        !loading && <p>No Withdrawal history found.</p>
      )}
    </div>
  );
};

export default Withdrawalhistory;
